import React from "react"
import {Helmet} from "react-helmet";
export const EmbededDashPopUp = () => {
    return(
        // <div id="start">
        //     <button data-tf-popup="i7i42URJ" 
        //     data-tf-iframe-props="title=Join Indiefolio (Airtable Connected)" 
        //     data-tf-medium="snippet"
        //     style={{all:"unset",fontFamily:"Poppins,Arial,sans-serif", display:"inline-block", maxWidth:"100%" , whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",backgroundColor:"#10C89B",color:"#000000",fontSize:"20px",borderRadius:"25px",padding:"0 33px",fontWeight:"bold",height:"50px",cursor:"pointer",lineHeight:"50px",textAlign:"center",margin:0,textDecoration:"none",color:"white"}}>Start Application
        //     </button>
        //     <Helmet><script src="//embed.typeform.com/next/embed.js"></script></Helmet>
        // </div>
        // <>
        // <button 
        // data-tf-popup="i7i42URJ" 
        // data-tf-iframe-props="title=Join Indiefolio (Airtable Connected)" 
        // data-tf-medium="snippet" 
        // data-tf-hidden="source=main website,medium=xxxxx,campaign=xxxxx" 
        // style={{all:"unset",fontFamily:"Helvetica,Arial,sans-serif",display:"inline-block",maxWidth:"100%",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",backgroundColor:"#12CC66",color:"#000000",fontSize:"20px",borderRadius:"25px",padding:"0 33px",fontWeight:"bold",height:"50px",cursor:"pointer",lineHeight:"50px",textAlign:"center",margin:"0",textDecoration:"none"}}
        // >
        //     Start Application
        // </button>
        // <Helmet><script src="//embed.typeform.com/next/embed.js"></script></Helmet>
        // </>


 
        <>
      {/* <div id="wrapper-cu2" style={{width:'100%', height:'100%'}} data-tf-widget="i7i42URJ" data-tf-inline-on-mobile data-tf-medium="snippet" data-tf-hidden="source=main website,medium=xxxxx,campaign=xxxxx"></div>  */}
      <div id="wrapper-cu2" style={{width:'100%', height:'100%', zIndex:'9998', position: 'relative'}} data-tf-live="01JEAXJ0JCTJSWK6F96XR78ATQ"></div>
        <Helmet>
        {/* <title>Join Indiefolio (Airtable Connected)</title> <style>*{margin:0;padding:0;} html,body,#wrapper{width:100%;height:100%;} iframe{border-radius:0 !important;}</style>  */}
            <script src="//embed.typeform.com/next/embed.js"></script>
        
        </Helmet>
        </>
        // <!DOCTYPE html> <html lang="en"> <head> <meta charset="utf-8" /> <meta name="viewport" content="width=device-width, initial-scale=1" /> <title>Join Indiefolio (Airtable Connected)</title> <style>*{margin:0;padding:0;} html,body,#wrapper{width:100%;height:100%;} iframe{border-radius:0 !important;}</style> </head> <body> <div id="wrapper" data-tf-widget="i7i42URJ" data-tf-inline-on-mobile data-tf-medium="snippet" data-tf-hidden="source=main website,medium=xxxxx,campaign=xxxxx"></div> <script src="//embed.typeform.com/next/embed.js"></script> </body> </html>
    )
}