import React,{Component} from 'react';
import Header from '../Header/header';
// import Footer from '../Footer/footer'
// import contact from   '../../../../Assets/images/contact.png'
import EmailModal from '../../Modal/Emailmodal';
import PasswordModal from '../../Modal/passwordModal';
import CreateNewAccount from '../../Modal/CreateNewAccount';
import LoginOptionsModal from '../../Modal/LoginOptionsModal';
import ForgetPasswordModal from '../../Modal/ForgetPasswordModal';
import { connect } from 'react-redux';
import { postAPI, putAPI } from '../../../../utils/API';

class Contactus extends Component{
    state = {
        emailModal: false,
        passwordModal: false,
        createNewAccountModal: false,
        firebaseOnlyUser: false,
        loginOptionsModal: false,
        forgetPasswordModal: false,
      }

      componentDidMount = () => {
      
        if (this.props.projectStatus === 2) {
          this.props.history.push("/dashboard");
        }
        if (this.props.isScopeOfWorkCompleted === true) {
          var localStorageData = localStorage.getItem("CollateralQuestions");
          localStorageData = JSON.parse(localStorageData);
          var BudgetData = localStorage.getItem("BudgetData");
          BudgetData = JSON.parse(BudgetData);
          var UnitData = localStorage.getItem("UnitData");
          UnitData = JSON.parse(UnitData);
          var StyleAnswers = localStorage.getItem("StyleQuestionData");
          StyleAnswers = JSON.parse(StyleAnswers);
          var TypeAnswers = localStorage.getItem("TypeQuestionData");
          TypeAnswers = JSON.parse(TypeAnswers);
          var DeadlineData = localStorage.getItem("DeadlineData");
          DeadlineData = JSON.parse(DeadlineData);
          var ProjectType = localStorage.getItem("ProjectType");
          ProjectType = JSON.parse(ProjectType);
          var ProjectName = localStorage.getItem("ProjectName");
          ProjectName= JSON.parse(ProjectName);

    
          
      
    
          let id = localStorageData?.collateral?._id;
          let collateral_type_answer = TypeAnswers?.question_answer;
          let collateral_style_answer = StyleAnswers?.question_answer;
          let minBudget = BudgetData?.min_budget;
          let maxBudget = BudgetData?.max_budget;
          let budgetType = BudgetData?.budget_type;
          let budgetUnit = BudgetData?.budget_unit;
          let unit = UnitData?.unit;
          let subtype=ProjectType.sub_type;
          let number_hour=BudgetData?.number_hour;
          let number_month=BudgetData?.number_month
          let project_name=ProjectName
          let total
          if(maxBudget){
       total=maxBudget* (number_month?number_month:1)
    
    }
          
          if (
            !minBudget ||
            !maxBudget ||
            (!DeadlineData?.dealline &&!subtype)||
            (UnitData && !unit&&subtype!=='retainer')
          ) {
            this.props.history.push("/hire");
          }
          this.props.OpenSignupLogin(false);
          const project_details = {
            collateral_id: id,
            question_answer_type:  collateral_type_answer?collateral_type_answer:'',
            question_answer_style: collateral_style_answer?collateral_style_answer:'',
            min_budget: minBudget,
            max_budget: maxBudget,
            unit: unit?unit:'',
            budget_type: budgetType,
            budget_unit: budgetUnit,
            dealline: DeadlineData?.dealline?DeadlineData.dealline:'',
            number_month:number_month?number_month:0,
            number_hour:number_hour?number_hour:0,
            sub_type:subtype?subtype:'',
            project_name
    
            
    
          };
          postAPI("/project/add-post-project-global", project_details)
          .then((res) => {
            console.log(res)
            const projectId = res.data.data._id;
            localStorage.setItem('postProjectId',projectId)

            if (res.data.status === "1") {
              if (this.props.isHireAgain) {
                const brief_details = {
                  post_project_id: projectId,
                  creator_id:this.props.hireAgainCreatorId,
                  posting_from:localStorage.getItem('prevPath'),
                  // user_ids: [{ user_id: hireAgainCreatorId }],
                  budget_scope:total>75000?'high':'low',
                  
                }
  
                putAPI('/project/add-post-project-creator-company', brief_details)
                  .then((res) => {
                    if (res.data.status === "1") {
                      this.props.history.push('/project_details/project');
                      this.props.changeHireAgainStatus(false)
                    }
                  })
                  .catch(err => console.log(err))
                  this.props.LoadPostProjectData(projectId);
              } 
                
                // var StyleAnswers = localStorage.getItem('StyleQuestionData');
    
                let keysToRemove = [
                  "CollateralQuestions",
                  "BudgetData",
                  "StyleQuestionData",
                  "DeadlineData",
                  "TypeQuestionData",
                  "UnitData",
                  "StyleSkip",
                  "TypeSkip"
                  
                ];
                keysToRemove.forEach((k) => localStorage.removeItem(k));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        
      };
    setHomeState = (state) => {
        this.setState(state)
      }
   
    render(){
        const url_string = window.location.href;
        const url = new URL(url_string);
        let previousUrl = url_string.split('previousUrl=')[1];
        // let previousUrl = url.searchParams.get('previousUrl');
        previousUrl = previousUrl ? previousUrl : '';
        const firstName = url.searchParams.get('firstname');
        const lastName = url.searchParams.get('lastname');
        const rehire = url.searchParams.get('rehire');
        let collateral = url.searchParams.get('collateral');
        collateral = collateral ? collateral : '';
        let name = url.searchParams.get('name');
        name = name ? name : ''
        let email = url.searchParams.get('email');
        email = email ? email : ''
        let num = url.searchParams.get('num'); num = num ? num : '';
        let creator = url.searchParams.get('creator'); creator = creator ? creator : ''

        let newPreviousUrl = previousUrl ? new URL(previousUrl) : null;
        let searchParamsFromPreviousUrl = newPreviousUrl && newPreviousUrl.search ? `${newPreviousUrl.search}&` : '?'
        
        const { emailModal, passwordModal, createNewAccountModal, loginOptionsModal, forgetPasswordModal} = this.state;
        const {page} = this.props; 
        const scheduleCall = this.props.history.location.pathname;
        // console.log(name, email, num, collateral);
        // const { userName,isProfileLoaded } = this.props

        return(
            <React.Fragment>
                    {emailModal && <EmailModal {...this.state} setHomeState={this.setHomeState} reload={this.props.reload}/>}
                    {passwordModal && <PasswordModal {...this.state} setHomeState={this.setHomeState} reload={this.props.reload}/>}
                    {createNewAccountModal && <CreateNewAccount {...this.state} setHomeState={this.setHomeState} reload={this.props.reload}/>}
                    {loginOptionsModal && <LoginOptionsModal {...this.state} setHomeState={this.setHomeState} reload={this.props.reload}/>}
                    {forgetPasswordModal && <ForgetPasswordModal setHomeState={this.setHomeState}/>}
                  {localStorage.getItem('SignUpidToken') ? null : <Header openModel={this.openModel} setHomeState={this.setHomeState} />}

                    {
                        this.props.location.pathname === "/contactus" 
                        ? <iframe title="fms" id="typeform-full" className="contact_page" frameBorder="0" allow="camera; microphone; autoplay; encrypted-media;" src="https://form.typeform.com/to/pWsLDHjZ"></iframe>
                        : <iframe  title="fms" id="typeform-full" className="contact_page" frameBorder="0" allow="camera; microphone; autoplay; encrypted-media;" 
                            src={scheduleCall === `/hire/schedule-call` 
                                ? `https://calendly.com/team-getcreator/hire-a-creative-expert-revised?hide_gdpr_banner=1&primary_color=10c89b&name=${name}&email=${email}` 
                                :
                                scheduleCall === "/hire/schedule-call-individual-creator"
                                    ? `https://calendly.com/getcreator/hire-individual-creator?hide_gdpr_banner=1&name=${name}&email=${email}&a1=${num}&a3=${creator}`
                                    :
                                    scheduleCall === "/hire/schedule-call-service-pages"
                                        ? `https://calendly.com/team-getcreator/hire-an-expert-individual-service-page?hide_gdpr_banner=1&name=${name}&email=${email}&a1=${num}&a3=${collateral}`
                                        :
                                        scheduleCall === "/onboarding-typeform"
                                        ? `https://indiefolio-cliffex.slack.com//embed.typeform.com/next/embed.js`
                                        :
                                        rehire === 'true' 
                                            ? `https://indiefolio.typeform.com/to/vekZJcc0${searchParamsFromPreviousUrl}firstname=${firstName}&lastname=${lastName}&previousurl=${previousUrl}`
                                            : 
                                            page === 'apply-creator' 
                                                ? `https://indiefolio.typeform.com/to/t2ZdQFoA${searchParamsFromPreviousUrl}collateral=${collateral}&landingurl=xxxxx&previousurl=${previousUrl}` 
                                                : 
                                                page !== 'services-hire' 
                                                    ? `https://form.typeform.com/to/mhn2OnRx${searchParamsFromPreviousUrl}landingurl=xxxxx&previousurl=${previousUrl}` 
                                                    : `https://form.typeform.com/to/o3YIgRe9${searchParamsFromPreviousUrl}collateral=${collateral}&landingurl=xxxxx&previousurl=${previousUrl}`
                                }
                            > 

                            </iframe>
                    }
                  
            </React.Fragment>
            )
        }
    }

const mapState = state => {
    const globalReducer = state.globalReducer;
    const postProjectData = state.postProject;
    return ({
        hireAgainCreatorId: postProjectData.hireAgainCreatorId,
        isHireAgain: postProjectData.isHireAgain,
        isScopeOfWorkCompleted: postProjectData.isScopeOfWorkCompleted,
        userName: globalReducer.Me.Username,
        isProfileLoaded: globalReducer.Me.isProfileLoaded,
    })
}
const mapDispatch = (dispatch) => ({
    LoadArtists: (projectId) =>
      dispatch({ type: "LOAD_ARTISTS", payload: projectId }),
    SelectArtist: (artistId) =>
      dispatch({ type: "CHOOSE_ARTIST", payload: artistId }),
    OpenPopup: (text) =>
      dispatch({ type: "OPEN_POST_PROJECT_POPUP", payload: { Component: text } }),
    LoadPostProjectData: (id) =>
      dispatch({ type: "LOAD_POST_PROJECT_DATA", payload: id }),
    LoadProjectData: (id) =>
      dispatch({ type: "LOAD_PROJECT_DATA", payload: { id: id } }),
    creatorList: (id) => dispatch({ type: "GET_CREATOR_LIST", payload: id }),
    OpenSignupLogin: (state) =>
      dispatch({ type: "OPEN_SIGNUP_LOGIN", payload: state }),
    resetProjectData: () =>
      dispatch({ type: "RESET_SINGLE_PROJECT_DATA", values: null }),
	changeHireAgainStatus: (val) => dispatchEvent({ type: 'UPDATE_HIRE_AGAIN_STATUS', values: {creatorId: '', isHireAgain: val} }),

    updateInviteMoreStatus: (value, isFormChat) =>
      dispatch({
        type: "UPDATE_INVITE_MORE_STATUS_SAGA",
        payload: { value, isFormChat },
      }),
  });
  
  export default connect(mapState, mapDispatch)(Contactus);