import React, { Component, lazy } from "react";
import { Switch, Route } from "react-router-dom";
// import { useLocation } from 'react-router';
import { connect } from "react-redux";
import DashboardHeader from "../../Components/authorized/Common/Header/dashboard-header";
import NewLeftSideBar from "../../Components/authorized/Common/Header/NewLeftSideBar";
import Popup from "../../Components/authorized/Popup";
import BootstrapLoader from "../../Components/authorized/Common/BootstrapLoader/BootstrapLoader";
import { getAPI } from "../../utils/API";
import RedirectToPublicProfile from "../../Components/authorized/ProfileCreator/RedirectToPublicProfile";
import RedirectToHire from "../../Components/authorized/PostProject/RedirectToHire";
// import Helmet from './helmet';
import HelmetComp from "./helmet";
import Postproject2 from "../../Components/authorized/PostProject/Postproject2";
import CallConfirmed from "../../Components/authorized/PostProject/ProjectAndCompanyDetailsComponents/Calendly/CallConfirmed";
import CalendarTest from "../../Components/authorized/CalendarTest";
import { analytics } from "firebase";
import { EmbededDashPopUp } from "../../Components/unauthorized/DashboardApplication/EmbededDashPopUp";
import ProjectsView from "../../Components/authorized/Home/DashboardNew/ProjectsView/ProjectsView";
import Proposal from "../../Components/authorized/Home/DashboardNew/Proposal/Proposal";
import ClientViewBrief from "../../Components/authorized/Home/DashboardNew/ClientViewBrief";
import ClientVersionHistory from "../../Components/authorized/Home/Dashboard/VersionHistory/ClientVersionHistory";
import CreatorVersionHistory from "../../Components/authorized/Home/Dashboard/VersionHistory/CreatorVersionHistory";
import { clearCacheData } from "../../utils/Helperfunctions";

// const AdminChatComponent = lazy(() => import('../../Components/authorized/AdminChat/AdminChatComponent'));
const Onboarding = lazy(() => import("../../Components/authorized/OnboardingProfile/Onboarding"));
const Profile = lazy(() => import("../../Components/authorized/Profile/profile"));
const NotFound = lazy(() => import("../../Components/authorized/Not-Found/NotFound"));
const CreatorProfile = lazy(() => import("../../Components/authorized/ProfileCreator/CreatorProfile"));
const UploadProject = lazy(() => import("../../Components/authorized/UploadProject/UploadProject"));
const PostProject = lazy(() => import("../../Components/authorized/PostProject/Postproject"));
const PublicCreatorProfile = lazy(() => import("../../Components/authorized/ProfileCreator/PublicCreatorProfile"));
const ProfileSelection = lazy(() => import("../../Components/authorized/PostProject/ProfileSelection"));
const PostProjectDetails = lazy(() => import("../../Components/authorized/PostProject/PostProjectDetails"));
// const AllService = lazy(() => import('../../Components/unauthorized/Common/Footer/Allservice'));
const Aboutus = lazy(() => import("../../Components/unauthorized/Common/Footer/aboutus"));
const Contactus = lazy(() => import("../../Components/unauthorized/Common/Footer/contactus"));
const ViewAllMobileScreen = lazy(() => import("../../Components/authorized/Home/MobileDashboard/ViewAllMobileScreen"));
const PrivacyPolicy = lazy(() => import("../../Components/unauthorized/PrivacyPolicy/PrivacyPolicy"));
const CreativeProfessionalAgreement = lazy(() =>
  import("../../Components/unauthorized/CreativeProfessionalAgreement/CreativeProfessionalAgreement")
);
const SubmitMilestone = lazy(() => import("../../Components/OngoingProjects/SubmitMilestone"));
const ArtistReview = lazy(() => import("../../Components/authorized/Home/Dashboard/FillArtistReview"));
const ServiceDetail = lazy(() => import("../../Components/unauthorized/Common/Footer/serviceDetail"));
const Blog = lazy(() => import("../../Components/unauthorized/Common/Footer/Blog"));
const ProjectPayment = lazy(() => import("../../Components/authorized/Chat/Payment"));
const SubmitMilestonesRawFiles = lazy(() => import("../../Components/OngoingProjects/SubmitMilestonesRawFiles"));
const TermsAndConditions = lazy(() => import("../../Components/unauthorized/TermsAndConditions/TermsAndConditions"));
const ChatInitialize = lazy(() => import("../../Components/authorized/Chat/ChatInitialize"));
const ChatFromRoute = lazy(() => import("../../Components/authorized/Chat/ChatFromRoute"));
const ResetPassword = lazy(() => import("../../Components/unauthorized/Modal/ResetPasswordModal"));
const Home = lazy(() => import("../../Components/unauthorized/Home/home"));
const HomeNew = lazy(() => import("../../Components/authorized/Home/homeNew"));
const ServicePages = lazy(() => import("../../Components/unauthorized/OtherPages/ServicePages"));
const ActiveBriefDetail = lazy(() => import("../../Components/authorized/Home/Dashboard/ActiveBriefClient/ActiveBriefDetail"));
const ThankYouTypeform = lazy(() => import("../../Components/unauthorized/ThankYouTypeform"));
const ActivePages = lazy(() => import("../../Components/unauthorized/OtherPages/ActivePages"));
// const GumstackIframe = lazy(() => import("../../Components/authorized/Common/Common/GumstackIframe"));


class RootRouter extends Component {
  state = {
    authIs: false,
    userRegistered: false,
    isModel: false,
    isPassModal: false,
    userEmail: "",
    holdFirebase: false,
    chooseUserIs: false,
    unverifiedEmail: false,
    userIsClient: false,
    userProfile: "",
    unVerifiedPhone: false,
    completeApplication: "",
    isProfileLoaded: false,
    requestedPathname: "",
    unAuthRoutes: [
      "postproject",
      "resetpwd",
      "creator",
      "get_artist_review",
      "allservices",
      "aboutus",
      "contactus",
      "blog",
      "servicedetail",
      "privacypolicy",
      "terms",
      "hire",
      "public_profile",
      "services",
      "self-service-hire",
      "thank-you-typeform",
      "talentconnect",
      "applycreator",
      "active-briefs",
      "/projects",
      "dashboard/jobs"
    ],
    checkJob: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.Component !== prevProps.Component) {
      const body = document.querySelector("body");

      if (this.props.Component.length) {
        // append body tags
        body.classList.add("modal-open");
      } else {
        // remove body tags
        body.classList.remove("modal-open");
      }
    }
  }

  closeModal = (e) => {
    // console.log(e)
    // debugger
    if (e.keyCode === 27 && this.props.Component.length) {
      this.props.closePopup();
    }
  };

  componentDidMount() {
    // close modal on press escape button listner
    document.querySelector("body").onkeydown = this.closeModal;

    // if (location.pathname == '/'){
    //   <Helmet>
    //       <title>hello</title>
    //   </Helmet>

    // }
    var storageToken = localStorage.getItem("SignUpidToken");
    if (storageToken) {
      this.handleProfileContent(storageToken);
    } else {
      if(!localStorage.getItem('isCacheCleared')){
        clearCacheData()
        localStorage.clear();
        localStorage.setItem('isCacheCleared',true)
      }
      const { location, history } = this.props;
      // console.log('INDEXROUTING')

      const isAuthRoute = !this.state.unAuthRoutes.includes(location.pathname.split("/")[1].toLowerCase());
      // console.log({isAuthRoute})
      if (location.pathname !== "/" && isAuthRoute) {
        // console.log({isAuthRoute, location})
        this.setState({ requestedPathname: location.pathname });
        // history.push("/");
      }
      this.setState({
        authIs: false,
        isProfileLoaded: true,
      });
      // if no token then set profileLoading = false
      // this.props.setProfileLoading(false)
    }
  }

  setIndexRoutingState = (obj) => {
    // console.log("name and state...", name,state)
    this.setState(obj);
  };

  handleJobRedirect = async (id) => {
    let res = await getAPI(`/postProject/get-check-project-already-accept-from-external-application?id=${id}`);
    let result = res && res?.data ? res.data?.accepted_job : "";
    return result;
  };

  handleProfileContent = (value) => {
    getAPI("/users/user-profile")
      .then((res) => {
        this.props.loadMe({
          name: "UPDATE_LOAD_LOGGEDIN_USER",
          value: res.data.data,
        });
        this.setState({ isProfileLoaded: true });
        const emailStatus = res.data.data.email_status;
        const verificationPhone = res.data.data.phone_status;
        const user_role = res.data.data.current_set_role;
        const dashboard_status = res.data.data.review_status;
        // console.log('user_role---->', user_role)
        if (emailStatus === 1) {
          if (user_role === 2) {
            if (verificationPhone === 0) {
              this.setState({
                authIs: false,
                unVerifiedPhone: true,
                userProfile: res.data.data,
                unverifiedEmail: false,
              });
            } else if (
              dashboard_status === 0 ||
              dashboard_status === 1 ||
              dashboard_status === 2 ||
              dashboard_status === 3 ||
              dashboard_status === 4 ||
              dashboard_status === 5
            ) {
              this.setState(
                {
                  authIs: true,
                  unVerifiedPhone: false,
                  completeApplication: dashboard_status,
                  userProfile: res.data.data,
                },
                async () => {
                  if (
                    (dashboard_status === 0 || dashboard_status === 4 || dashboard_status === 5) &&
                    this.props.location.pathname === "/active-briefs"
                  ) {
                    // this.setState({
                    //   checkJob:true
                    // })
                    this.props.history.push("/dashboard");
                    setTimeout(() => {
                      alert("Please complete your onboarding to view jobs.");
                    }, 1000);
                  }
                  if (dashboard_status === 1 || dashboard_status === 2 || dashboard_status === 3) {
                    if (this.props.location.pathname == "/active-briefs") {
                      let location = this.props.location?.state;
                      let resp = "";
                      if (location && location?.project_id) {
                        resp = await this.handleJobRedirect(location?.project_id);
                        if (resp) {
                          this.props.history.push(`/dashboard`);
                          setTimeout(() => {
                            alert("The project has been moved or your proposal has been already selected.");
                          }, 500);
                        } else {
                          this.props.history.push(`/dashboard/jobs/:${location?.project_id}/${location?.project_slug}`);
                        }
                      } else {
                        this.props.history.push(`/dashboard/jobs`);
                      }
                    }
                  }
                }
              );
            }
          } else if (user_role === 1 || user_role === 3) {
            this.setState(
              {
                authIs: true,
                userProfile: res.data.data,
                unverifiedEmail: false,
              },
              () => {
                if (this.props.location.pathname == "/active-briefs" || this.props.location.pathname == "/dashboard/jobs") {
                  this.props.history.push("/");
                }
              }
            );
          }
        } else {
          this.setState({
            authIs: false,
            unverifiedEmail: true,
            userProfile: res.data.data,
          });
        }
      })
      .catch((error) => {
        console.log("user-profile-review api error---->", error);
        this.setState({ isProfileLoaded: true });
      });
  };

  reload = (stateObj) => {
    // if(stateObj){
    //   this.setState({stateObj})
    // }
    this.componentDidMount();
  };

  render() {
    const { CurrentRole } = this.props;
    const { isProfileLoaded } = this.state;
    if (!isProfileLoaded) return <BootstrapLoader />;
    // return this.props.isAuth ?
    const { location,history } = this.props;
    // console.log(location);

    const path = this.props?.history?.location?.pathname?.split("/");

    if(this.state.authIs && location.pathname === '/login') history.push('/') 

    return (
      <>
        <HelmetComp location={location} />

        {this.state.authIs ? (
          <React.Fragment>
            {/* <DashboardHeader
              {...this.props}
              {...this.state}
              setIndexRoutingState={this.setIndexRoutingState}
            /> */}
            <NewLeftSideBar {...this.props} {...this.state} setIndexRoutingState={this.setIndexRoutingState} />
            <Switch>
              {/* <Route exact path ="/calendarTest" render={() => <CalendarTest /> }/> */}
              <Route
                exact
                path="/"
                render={(props) => (
                  <HomeNew1 {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              
              <Route
                path="/dashboard"
                render={(props) => (
                  <HomeNew {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route exact path="/onboarding-apply" render={(props) => <EmbededDashPopUp />} />
               <Route
              path="/contactus"
              render={(props) => (
                <Contactus {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
              )}
            />
              <Route
                path="/dashboard/:subroute"
                render={(props) => (
                  <HomeNew {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route
                path="/dashboard/jobs/:subroute"
                render={(props) => (
                  <HomeNew {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route
                exact
                path={["/projects/:projectId/:subroute", "/projects/:projectId/:subroute/:connectionId", "/projects/:projectId/:subroute/:connectionId/:tabs"]}
                render={(props) => <ProjectsView {...props} {...this.state} reload={this.reload} />}
              />
                {/* <Route
                exact
                path={["/gumstack_call"]}
                render={(props) => <GumstackIframe {...props} {...this.state} reload={this.reload} />}
              /> */}
              
              {/* <Route
                exact
                path="/projects/:projectId/:subroute/:connectionId"
                render={(props) => <ProjectsView {...props} {...this.state} reload={this.reload} />}
              /> */}
              <Route
                path="/Profile"
                render={(props) => (
                  <Profile {...props} {...this.state} setIndexRoutingState={this.setIndexRoutingState} reload={this.reload} />
                )}
              />
              <Route path="/Onboarding" render={(props) => <Onboarding {...props} {...this.state} reload={this.reload} />} />
              <Route
                path="/CreatorProfile"
                render={(props) => <CreatorProfile {...props} {...this.state} reload={this.reload} />}
              />
              <Route path="/uploadproject/" render={(props) => <UploadProject {...props} {...this.state} />} />
              <Route path="/talentconnect/:projectId" render={(props) => <ActiveBriefDetail {...props} {...this.state} />} />

              <Route
                path="/services/"
                render={(props) => (
                  <ServicePages
                    {...props}
                    {...this.state}
                    setIndexRoutingState={this.setIndexRoutingState}
                    reload={this.reload}
                  />
                )}
              />
              <Route path="/hire/call-confirmed/" render={(props) => <CallConfirmed {...props} {...this.state} />} />
              <Route path="/self-service-hire/" render={(props) => <PostProject {...props} {...this.state} />} />
              <Route
                path="/hire/schedule-call"
                render={(props) => (
                  <Contactus page="schedule-call" {...props} {...this.state} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route
                path="/onboarding-typeform"
                render={(props) => (
                  <Contactus {...props} {...this.state} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route path="/hire" render={(props) => <Postproject2 {...props} {...this.state} />} />
              <Route path="/postproject/:collateral" render={(props) => <RedirectToHire />} />
              <Route path="/profile_selection" render={(props) => <ProfileSelection {...props} {...this.state} />} />
              <Route path="/project_details" render={(props) => <PostProjectDetails {...props} {...this.state} />} />
              <Route path="/creator/:user_name" render={(props) => <PublicCreatorProfile {...props} {...this.state} />} />
              <Route path="/public_profile/:user_name" render={(props) => <RedirectToPublicProfile />} />

              <Route path="/submitMilestone" render={(props) => <SubmitMilestone {...props} {...this.state} />} />
              <Route path="/rawFiles" render={(props) => <SubmitMilestonesRawFiles {...props} {...this.state} />} />
              <Route path="/payment" render={(props) => <ProjectPayment {...props} {...this.state} />} />
              <Route path="/viewAll/:dashboardItem" render={(props) => <ViewAllMobileScreen />} />
              <Route exact path="/get_artist_review/:token" render={(props) => <ArtistReview {...props} />} />

              <Route exact path="/adminChat" render={(props) => <ChatInitialize {...props} {...this.state} />} />

              {/* testing... */}
              <Route
                exact
                path="/adminChat/:projectId/:projectName"
                render={(props) => <ChatFromRoute {...props} {...this.state} />}
              />

              <Route exact path="/chat" render={(props) => <ChatInitialize {...props} {...this.state} />} />

              <Route
                exact
                path="/chat/:projectId/:projectName"
                // component={ChatFromRoute}
                render={(props) => <ChatFromRoute {...props} authIs={this.state.authIs} />}
              />

              <Route
                exact
                path="/chat/:projectId/:projectName/:connectionId"
                // component={ChatFromRoute}
                render={(props) => <ChatFromRoute {...props} authIs={this.state.authIs} />}
              />

              <Route exact path="/proposal/:id/:type/:jobs" render={(props) => <Proposal CurrentRole={CurrentRole} {...props} />} />
              {(CurrentRole === 1 || CurrentRole === 3) && (
                <Route
                  exact
                  path="/viewBrief/:id/:userId"
                  render={(props) => <ClientViewBrief CurrentRole={CurrentRole} {...props} />}
                />
              )}

              {(CurrentRole === 1 || CurrentRole === 3) && (
                <Route
                  exact
                  path="/version-history/:id/:userId"
                  render={(props) => <ClientVersionHistory CurrentRole={CurrentRole} {...props} />}
                />
              )}
              {CurrentRole != 1 && CurrentRole != 3 && (
                <Route
                  exact
                  path="/version-history/:id"
                  render={(props) => <CreatorVersionHistory CurrentRole={CurrentRole} {...props} />}
                />
              )}

              <Route path="/thank-you-typeform" render={() => <ThankYouTypeform />} />
              <Route exact path="/privacypolicy" render={() => <PrivacyPolicy />} />
              <Route exact path="/terms" render={() => <TermsAndConditions />} />
              <Route exact path="/creatoragreement" render={() => <CreativeProfessionalAgreement />} />
              <Route exact path="*" render={() => <NotFound />} />
            </Switch>
            <Popup />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {path?.[1] == "projects" && path?.[3] == "overview" ? <NewLeftSideBar {...this.props} {...this.state} setIndexRoutingState={this.setIndexRoutingState} /> : null}
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home {...props} {...this.state} setIndexRoutingState={this.setIndexRoutingState} reload={this.reload} />
                )}
              />
              <Route
                path="/services/"
                render={(props) => (
                  <ServicePages {...props} {...this.state} setIndexRoutingState={this.setIndexRoutingState} reload={this.reload} />
                )}
              />
                    <Route
              exact
              path="/login"
              render={(props) => (
                <Home
                  {...props}
                  {...this.state}
                  login={true}
                  setIndexRoutingState={this.setIndexRoutingState}
                  reload={this.reload}
                />
              )}
            />
            <Route
              exact
              path="/signup"
              render={(props) => (
                <Home
                  {...props}
                  {...this.state}
                  login={true}
                  setIndexRoutingState={this.setIndexRoutingState}
                  reload={this.reload}
                />
              )}
            />
              <Route
                path="/active-briefs"
                render={(props) => (
                  <ActivePages
                    {...props}
                    {...this.state}
                    publicJobs={true}
                    setIndexRoutingState={this.setIndexRoutingState}
                    reload={this.reload}
                  />
                )}
              />

              <Route
                path="/dashboard/jobs/:subroute"
                render={(props) => (
                  <Home {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />

              <Route
                path="/applycreator"
                render={(props) => (
                  <Contactus
                    page="apply-creator"
                    {...props}
                    {...this.state}
                    reload={this.reload}
                    setIndexRoutingState={this.setIndexRoutingState}
                  />
                )}
              />
              <Route path="/talentconnect/:projectId" render={(props) => <ActiveBriefDetail {...props} {...this.state} />} />
              {/* <Route
            exact
              path="/hire/schedule-call"
              render={(props) => <Contactus {...props} {...this.state} reload={this.reload}  setIndexRoutingState={this.setIndexRoutingState}/>}
            /> */}
              <Route
                exact
                path="/hire/call-confirmed"
                render={(props) => (
                  <Contactus {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />

              <Route
                exact
                path={"/projects/:projectId/:subroute"}
                render={(props) => <ProjectsView {...props} {...this.state} reload={this.reload} />}
              />

              <Route path="/hire" render={(props) => <Postproject2 {...props} {...this.state} />} />
              <Route path="/self-service-hire/" render={(props) => <PostProject {...props} {...this.state} />} />
              <Route path="/postproject/:collateral" render={(props) => <RedirectToHire />} />
              <Route exact path="/resetpwd" render={(props) => <ResetPassword {...props} />} />
              <Route exact path="/creator/:user_name" render={(props) => <PublicCreatorProfile {...props} {...this.state} />} />
              <Route
                path="/creator/:user_name/:project_id/:projectname"
                render={(props) => <PublicCreatorProfile {...props} {...this.state} />}
              />
              <Route path="/public_profile/:user_name" render={(props) => <RedirectToPublicProfile />} />
              <Route exact path="/get_artist_review/:token" render={(props) => <ArtistReview {...props} />} />
              {/* <Route
          path="/Allservices"
          render={(props) => <AllService {...props} {...this.state} reload={this.reload}  setIndexRoutingState={this.setIndexRoutingState}/>}
        /> */}
              <Route
                path="/aboutus"
                render={(props) => (
                  <Aboutus {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route
                path="/contactus"
                render={(props) => (
                  <Contactus {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route
                path="/blog"
                render={(props) => (
                  <Blog {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route
                path="/servicedetail"
                render={(props) => (
                  <ServiceDetail {...props} {...this.state} reload={this.reload} setIndexRoutingState={this.setIndexRoutingState} />
                )}
              />
              <Route path="/thank-you-typeform" render={() => <ThankYouTypeform />} />
              <Route exact path="/privacypolicy" render={() => <PrivacyPolicy />} />
              <Route exact path="/terms" render={() => <TermsAndConditions />} />

              {/* <Route exact path="/dashboard" render={() => this.props.history.push('/') } /> */}
              {/* <Route exact path="/testing" render={() => <TestComponent />} /> */}

              {/* <Route exact path="*" render={() => <NotFound />} /> */}
              <Route exact path="*" render={(props) => <Home {...props} {...this.state} login={true} setIndexRoutingState={this.setIndexRoutingState}  reload={this.reload} />} />
            
            </Switch>
          </React.Fragment>
        )}
      </>
    );
  }
}

const mapState = (store) => {
  let globalReducer = store.globalReducer;
  let creatorProfile = store.creatorProfile;
  return {
    Me: globalReducer.Me,
    isAuth: globalReducer.Me.isAuth,
    CurrentRole: globalReducer.Me.CurrentRole,
    Component: creatorProfile.Component,
  };
};

const mapDispatch = (dispatch) => ({
  closePopup: () => dispatch({ type: "CLOSE_POPUP_SAGA", payload: {} }),
  loadMe: (text) => dispatch({ type: "ON_STATEUPDATE_SAGA", payload: text }),
  setProfileLoading: (val) => dispatch({ type: "UPDATE_PROFILE_LOADED", values: val }),
});

export default connect(mapState, mapDispatch)(RootRouter);

class HomeNew1 extends Component {
  componentDidMount() {
    this.props.history.push("/dashboard");
  }
  render() {
    return <></>;
  }
}
